import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'

function Navbar() {
  return (
    <div className="menu">
      <div><Link to="/">Главная</Link></div>
      <div><Link to="/FamilyShoot">Семейная фотосессия</Link></div>
      <div><Link to="/IndividualShoot">Индивидуальная съёмка</Link></div>
      <div><Link to="/ChildrenShoot">Детские фотосессии</Link></div>
      <div><Link to="/ExpressShoot">Экспресс-фотосессия</Link></div>
      <div><Link to="/LoveStory">Love story</Link></div>
      <div><Link to="/WeddingShoot">Свадебные фото</Link></div>
    </div>
  );
}

export default Navbar;