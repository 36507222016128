import React from "react";
import './header.css'
import photoDaria from '../../assets/images/Daria.jpg';

const Header = ({toDo, done}) => {
    return (
      <div className="header">
        <h1>Дарья Пузикова</h1>
        <h2>Фотограф</h2>
        <h3>Персональный сайт</h3>
        <img src={photoDaria} alt="Daria" className="photoDaria"/>
        <h2>Меню</h2>
      </div>
    );
  };

export default Header;