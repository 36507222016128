import './App.css';
import Header from '../header/header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Импортируйте Routes и Route
import Navbar from '../navbar/Navbar';
import Home from '../../pages/Home';
import FamilyShoot from '../../pages/FamilyShoot';
import IndividualShoot from '../../pages/IndividualShoot';
import ChildrenShoot from '../../pages/ChildrenShoot';
import ExpressShoot from '../../pages/ExpressShoot';
import LoveStory from '../../pages/LoveStory';
import WeddingShoot from '../../pages/WeddingShoot';


function App() {
  return (
    <Router>
      <Header />
      <Navbar /> {/* Ваше навигационное меню */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/FamilyShoot" element={<FamilyShoot />} />
        <Route path="/IndividualShoot" element={<IndividualShoot />} />
        <Route path="/ChildrenShoot" element={<ChildrenShoot />} />
        <Route path="/ExpressShoot" element={<ExpressShoot />} />
        <Route path="/LoveStory" element={<LoveStory />} />
        <Route path="/WeddingShoot" element={<WeddingShoot />} />
      </Routes>
    </Router>
  );
}

export default App;
