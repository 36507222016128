import React from 'react';
import ImageSlider3 from '../components/slider3/slider3';

function Home() {
  return (
    <div className="App">
      <h3>Прайс-лист</h3>
      <p>Для тех, кому не нужно слишком много фотографий и хочется побыстрее ☺️ 2000 р за 30 минут.</p>
      <p>Что входит в стоимость :</p>
      <p>. Помощь с подбором образа</p>
      <p>. 20-25 фото в цветокоррекции</p>


      <p>Репортажная съёмка 3500 р /час (если 3 часа и более, то 3000 р/час)</p>

      <p>Съёмка праздников, сопровождение экспертов на выставках и других мероприятиях.</p>
      <ImageSlider3 folderName="Home" />
    </div>
  );
}

export default Home;